<template>
  <v-stepper
    v-model="step"
    vertical
  >
    <v-stepper-step
      :complete="step > 1"
      step="1"
    >
      Please install the authentication app (Google, Microsoft) and scan the QR code below
    </v-stepper-step>

    <v-stepper-content step="1">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col col="12">Scan the QR code</v-col>
          </v-row>
          <v-row>
            <v-col col="12">
              <img v-if="qrCode" :src="qrCode" alt="qr">
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="mt-5"
            color="primary"
            @click="nextStep()"
          >
            Continue
          </v-btn>
          <v-btn outlined color="secondary" class="mt-5 ml-5" @click="cancel()">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-stepper-content>

    <v-stepper-step
      :complete="step > 2"
      step="2"
    >
      Enter the code from the authenticator
    </v-stepper-step>

    <v-stepper-content step="2">
      <v-card>
        <authenticator-verification @process="nextStep()" @back="back()" :step="step"></authenticator-verification>
      </v-card>
    </v-stepper-content>

    <v-stepper-step
      :complete="step > 3"
      step="3"
    >
      2FA Activation
    </v-stepper-step>

    <v-stepper-content step="3">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col col="12">Please complete 2FA activation. </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="mt-5"
            color="primary"
            @click="enable2fa()"
          >
            Enable 2fa
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-stepper-content>
  </v-stepper>
</template>

<script>
import { ref } from '@vue/composition-api';
import store from '@/store';
import Vue from 'vue';
import { required } from '@core/utils/validation';
import AuthenticatorVerification from '@/views/shared/2fa/AuthenticatorVerification.vue';

export default {
  components: { AuthenticatorVerification },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const step = ref(1);
    const qrCode = ref(null);

    const generateAuthenticatorQr = () => {
      store.dispatch('profile/generateAuthenticatorQr').then((qrBase64) => {
        qrCode.value = qrBase64;
      }).catch((error) => {
        Vue.notify({
          type: 'error',
          title: 'Verification',
          text: error.response.data.message,
        });
      });
    };

    generateAuthenticatorQr();

    const verifyAuthenticatorCode = () => {
      store.dispatch('profile/verifyCode').then(() => {
        nextStep();
      }).catch((error) => {
        Vue.notify({
          type: 'error',
          title: 'Verification',
          text: error.response.data.message,
        });
      });
    };

    const enable2fa = () => {
      store.dispatch('profile/enable2fa').then((response) => {
        if (response.is2faEnabled) {
          emit('success');
        }
      }).catch((error) => {
        Vue.notify({
          type: 'error',
          title: '2FA',
          text: error.response.data.message,
        });
      });
    };

    const nextStep = () => {
      emit('updateProfile');
      step.value = ++step.value;
    };

    const back = () => {
      step.value = --step.value;
    };

    const cancel = () => {
      emit('cancel');
    };

    return {
      step,
      qrCode,
      nextStep,
      enable2fa,
      back,
      cancel,
      verifyAuthenticatorCode,
      validators: { required },
    };
  }
};
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user';
</style>
