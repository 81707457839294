<template>
  <div>
    <v-alert v-if="isShowSuccessAlert" color="success" text>
      <p class="font-weight-semibold mb-1">Success</p>
      <p class="text-sm mb-1">2-factor authentication has been successfully configured. Now you can use the website.</p>
      <v-btn
        class="mt-5"
        color="primary"
        @click="isShowSuccessAlert = false"
      >
        Continue
      </v-btn>
    </v-alert>
    <v-row v-if="!user.is2faEnabled">
      <v-col>
        <v-alert color="warning" text>
          <p class="font-weight-semibold mb-1">Set Up 2-Factor Authentication (2FA)</p>
        </v-alert>
        <v-card v-if="!selected2FaType" class="select-2fa-type p-5">
          <v-card-text>
            <v-radio-group
              v-model="twoFaType"
              column
            >
              <v-radio
                class="mobile-phone-radio-button"
                label="Mobile Phone"
                value="phone"
              ></v-radio>
              <v-radio
                class="authentication-app-radio-button"
                label="Authentication App (Google, Microsoft)"
                value="authenticator"
              ></v-radio>
            </v-radio-group>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <div class="d-flex justify-space-between mt-3">
              <v-btn color="primary" class="me-3" type="submit" @click.prevent="onSubmit"> Select </v-btn>
            </div>
          </v-card-actions>
        </v-card>
        <phone2fa v-if="selected2FaType === twoFaTypeEnum.PHONE" @updateProfile="updateProfile()" @cancel="cancel()"  @success="success()" :user="user"></phone2fa>
        <authenticator2fa v-else-if="selected2FaType === twoFaTypeEnum.AUTHENTICATOR" @cancel="cancel()" @success="success()" :user="user"></authenticator2fa>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api';
import Phone2fa from '@/views/shared/2fa/Phone2fa.vue';
import Authenticator2fa from '@/views/shared/2fa/Authenticator2fa.vue';
import { TwoFaTypeEnum } from '@/enums/two-fa-type.enum';
import store from '@/store';
import Vue from 'vue';

export default {
  components: { Authenticator2fa, Phone2fa },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const isShowSuccessAlert = ref(false);
    const selected2FaType = ref(false);
    const twoFaType = ref(false);
    const twoFaTypeEnum = TwoFaTypeEnum;

    const onSubmit = () => {
      store.dispatch('profile/pathTwoFaType', { twoFaType: twoFaType.value }).then(() => {
        selected2FaType.value = twoFaType.value;
      }).catch((error) => {
        Vue.notify({
          type: 'error',
          title: 'Verification',
          text: error.response.data.message,
        });
      });
    };

    const cancel = () => {
      selected2FaType.value = null;
    };

    const updateProfile = () => {
      emit('updateProfile');
    };

    const success = () => {
      emit('updateProfile');
      isShowSuccessAlert.value = true;

      setTimeout(()=> {
        isShowSuccessAlert.value = false;
      }, 20000);
    };

    return {
      onSubmit,
      updateProfile,
      twoFaTypeEnum,
      twoFaType,
      selected2FaType,
      isShowSuccessAlert,
      cancel,
      success,
    };
  }
};
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user';
</style>
