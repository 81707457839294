<template>
  <v-form ref="form" v-model="valid" class="pt-2 multi-col-validation" @submit.prevent="onSubmit">
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="code"
          :rules="[validators.required]"
          :error-messages="errorMessages.code"
          @change="errorMessages.code = null"
          outlined
          dense
          id="code"
          label="Enter the code"
        ></v-text-field>
      </v-col>
      <v-col cols="12" class="d-flex mt-3">
        <v-btn color="primary" class="me-3" type="submit" @click.prevent="onSubmit"> Submit </v-btn>

        <v-btn outlined color="secondary" @click="back()">
          Cancel
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { ref } from '@vue/composition-api';
import { required } from '@core/utils/validation';
import store from '@/store';
import handleError from '@/plugins/handle-error';

export default {
  setup(props, { emit }) {
    const valid = ref(false);
    const form = ref(null);
    const code = ref(null);
    const errorMessages = ref({ code: null });
    const validate = () => {
      form.value.validate();
    };

    const onSubmit = () => {
      if (valid.value) {
        store.dispatch('profile/check2fa', { code: code.value }).then(() => {
          store.dispatch('profile/profile');
          emit('process');
        }).catch(error => (errorMessages.value = { code: handleError(error) }));
      } else {
        validate();
      }
    };

    const back = () => {
      emit('back');
    };

    return {
      form,
      code,
      onSubmit,
      valid,
      validate,
      errorMessages,
      back,
      validators: { required },
    };
  },
};
</script>
