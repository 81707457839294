<template>
  <div id="user-view">
    <v-row>
      <v-col cols="12" md="5" lg="4">
        <user-bio-panel v-if="user" @updateProfile="getProfile()"></user-bio-panel>
      </v-col>
      <v-col cols="12" md="7" lg="8">
        <setup2fa v-if="user" @updateProfile="getProfile()" :user.sync="user"></setup2fa>
        <div v-if="user">
          <v-tabs v-model="tab" show-arrows class="user-tabs">
            <v-tab v-for="tab in tabs" :key="tab.icon">
              <v-icon size="20" class="me-3">
                {{ tab.icon }}
              </v-icon>
              <span>{{ tab.title }}</span>
            </v-tab>
          </v-tabs>

          <v-tabs-items id="user-tabs-content" v-model="tab" class="mt-5 pa-1">
            <v-tab-item>
              <user-security></user-security>
            </v-tab-item>
          </v-tabs-items>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api';
import { mdiLockOutline } from '@mdi/js';
import Setup2fa from '@/views/shared/2fa/Setup2fa.vue';
import store from '@/store';
import UserSecurity from '@/views/shared/users/_partials/UserSecurity.vue';
import UserBioPanel from '@/views/shared/users/UserBioPanel.vue';

export default {
  components: {
    UserBioPanel,
    UserSecurity,
    Setup2fa,
  },
  setup() {
    const user = ref(null);
    const tab = ref(null);

    const getProfile = () => {
      store.dispatch('profile/profile').then((profile) => {
        user.value = profile;
      });
    };

    getProfile();

    return {
      tabs: [{ icon: mdiLockOutline, title: 'Security' }],
      tab,
      getProfile,
      user,
    };
  },
};
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
