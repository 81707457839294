<template>
  <v-stepper
    v-model="step"
    vertical
  >
    <v-stepper-step
      :complete="step > 1"
      step="1"
    >
      Enter the phone number
    </v-stepper-step>

    <v-stepper-content step="1">
      <v-card v-if="!showPhoneEditPhone">
        <v-card-text>
          <v-row>
            <v-col col="12">Please check if the number is correct</v-col>
          </v-row>
          <v-row>
            <v-col col="12">{{user.phone}}</v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-checkbox
                class="consent-to-receive-one-time-passcodes"
                v-model="privacy"
                :label="`By checking the box I give my consent to receive one-time passcodes to access Merchanto platform`"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            :disabled="!privacy"
            class="send-code-button mt-5"
            color="primary"
            @click="verifyPhone()"
          >
            Send code
          </v-btn>
          <v-btn outlined color="secondary" class="edit-phone-button mt-5 ml-5" @click="showPhoneEditPhone = true">
            Edit phone
          </v-btn>
          <v-btn outlined color="secondary" class="cancel-button mt-5 ml-5" @click="cancel()">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card style="min-height: 290px;" v-else>
        <v-card-text>
          <v-row>
            <v-col class="col-12 col-md-12">
              <p>Please use mobile carrier phone numbers only (do not use landline phone numbers)</p>
              <vue-phone-number-input v-model="newPhoneNumber.phone" :no-use-browser-locale="true" v-bind="newPhoneNumber.props" @update="phoneNumberEntered($event)"/>
              <v-alert v-if='unsupportedCountryError' color="danger" text class="mt-4">
                <p class="font-weight-semibold mb-1">
                  Unfortunately, sending SMS messages to the {{ displayedUnsupportedCountryName }} is currently not supported. Please consider using another authentication method.
                </p>
              </v-alert>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="mt-5"
            color="primary"
            @click="update()"
          >
            Update
          </v-btn>
          <v-btn outlined color="secondary" class="mt-5 ml-5" @click="user.phone ? showPhoneEditPhone = false : cancel()">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-stepper-content>

    <v-stepper-step
      :complete="step > 2"
      step="2"
    >
      Phone number verification
    </v-stepper-step>

    <v-stepper-content step="2">
      <v-card>
        <phone-verification v-if='user.phone' @process="nextStep()" @back="back()" :phone="user.phone" :step="step"></phone-verification>
      </v-card>
    </v-stepper-content>

    <v-stepper-step
      :complete="step > 3"
      step="3"
    >
      2FA Activation
    </v-stepper-step>

    <v-stepper-content step="3">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col col="12">Please complete 2FA activation. </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="mt-5"
            color="primary"
            @click="enable2fa()"
          >
            Enable 2fa
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-stepper-content>
  </v-stepper>
</template>

<script>
import { ref } from '@vue/composition-api';
import store from '@/store';
import Vue from 'vue';
import { required } from '@core/utils/validation';
import PhoneVerification from '@/views/shared/2fa/PhoneVerification.vue';

export default {
  components: { PhoneVerification },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const step = ref(1);
    const showPhoneEditPhone = ref(false);
    const privacy = ref(false);
    const newPhoneNumber = ref({
      phone: '',
      results: {},
      props: {
        clearable: true,
        fetchCountry: false,
        preferredCountries: [],
        noExample: false,
        translations: {
        countrySelectorLabel: 'Country code',
          countrySelectorError: 'Error',
          phoneNumberLabel: 'Enter your phone number',
          example: 'Example:'
        }
      }
    });
    const unsupportedCountryError = ref(false);
    const displayedUnsupportedCountryName = ref(null);
    const unsupportedCountries = ['AE'];
    const unsupportedCountryErrorMap = {
      'AE': 'United Arab Emirates',
    };

    const isCountrySupported = (countryCode) => !unsupportedCountries.includes(countryCode);

    const displayUnsupportedCountryError = (countryCode) => {
      unsupportedCountryError.value = true;
      displayedUnsupportedCountryName.value = unsupportedCountryErrorMap[countryCode] ?? 'this country';
    };

    const hideUnsupportedCountryError = () => {
      unsupportedCountryError.value = false;
      displayedUnsupportedCountryName.value = null;
    };

    const phoneNumberEntered = (phoneNumber) => {
      hideUnsupportedCountryError();

      if (!phoneNumber.countryCode) {
        return;
      }

      if (!isCountrySupported(phoneNumber.countryCode)) {
        displayUnsupportedCountryError(phoneNumber.countryCode);
        return;
      }

      newPhoneNumber.value.results = phoneNumber;
    };

    if (!props.user.phone) {
      showPhoneEditPhone.value = true;
    }

    if (props.user.isPhoneVerified) {
      step.value = 3;
    }

    const verifyPhone = () => {
      store.dispatch('profile/verifyPhone').then(() => {
        nextStep();
      }).catch((error) => {
        Vue.notify({
          type: 'error',
          title: 'Phone verification',
          text: error.response.data.message,
        });
      });
    };

    const update = () => {
      if (newPhoneNumber.value.results.isValid) {
        store.dispatch('profile/patchPhone', { phone: newPhoneNumber.value.results.formattedNumber }).then(() => {
          showPhoneEditPhone.value = false;
          emit('updateProfile');
        }).catch((error) => {
          Vue.notify({
            type: 'error',
            title: 'Phone verification',
            text: error.response.data.message,
          });
        });
      }
    };

    const enable2fa = () => {
      store.dispatch('profile/enable2fa').then((response) => {
        if (response.is2faEnabled) {
          emit('success');
        }
      }).catch((error) => {
        Vue.notify({
          type: 'error',
          title: '2FA',
          text: error.response.data.message,
        });
      });
    };

    const nextStep = () => {
      emit('updateProfile');
      step.value = ++step.value;
    };

    const back = () => {
      step.value = --step.value;
    };

    const cancel = () => {
      emit('cancel');
    };

    return {
      step,
      verifyPhone,
      nextStep,
      enable2fa,
      back,
      update,
      privacy,
      cancel,
      showPhoneEditPhone,
      newPhoneNumber,
      validators: { required },
      phoneNumberEntered,
      unsupportedCountryError,
      displayedUnsupportedCountryName,
    };
  }
};
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user';
</style>
