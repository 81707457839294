<template>
  <v-form ref="form" v-model="valid" class="multi-col-validation" @submit.prevent="onSubmit">
    <v-card-text>
      <v-col cols="12">
        <div class="pb-5">
          Verification code was sent to {{phone}}. Enter the code to set up 2FA
        </div>
        <v-text-field
          v-model="code"
          :rules="[validators.required]"
          :error-messages="errorMessages.code"
          @change="errorMessages.code = null"
          outlined
          dense
          id="code"
          label="Code"
        ></v-text-field>
      </v-col>
      <div class="w-full d-flex text-center justify-center mt-3" v-if="countDown">You can request a new code in {{ countDown }} seconds</div>
      <v-col v-else cols="12" class="d-flex justify-center mt-3">
        <v-btn text color="primary" @click="resendCode()">
          Resend code
        </v-btn>
      </v-col>
    </v-card-text>
    <v-card-actions>
      <v-btn color="primary" class="me-3" type="submit" @click.prevent="onSubmit"> Submit </v-btn>
      <v-btn outlined color="secondary" @click="back()">
        Cancel
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
import { ref, watch } from '@vue/composition-api';
import { required } from '@core/utils/validation';
import store from '@/store';
import handleError from '@/plugins/handle-error';

export default {
  props: {
    step: {
      type: Number,
      required: true,
    },
    phone: {
      type: String,
      required: true,
    },
  },

  setup(props, { emit }) {
    const valid = ref(false);
    const form = ref(null);
    const countDown = ref(60);
    const code = ref(null);
    const errorMessages = ref({ code: null });
    const validate = () => {
      form.value.validate();
    };

    const resendCode = () => {
      store.dispatch('profile/verifyPhone').then(() => {
        countDown.value = 60;
        countDownTimer();
      }).catch(error => (errorMessages.value = { code: handleError(error) }));
    };

    const countDownTimer = () => {
      if (countDown.value < 0) {
        countDown.value = 0;
      }
      if (countDown.value > 0) {
        setTimeout(() => {
          countDown.value -= 1;
          countDownTimer();
        }, 1000);
      }
    };

    watch(
      () => props.step,
      () => {
        if (props.step === 2) {
          countDown.value = 60;
          countDownTimer();
        }
      },
    );

    const onSubmit = () => {
      if (valid.value) {
        store.dispatch('profile/verifyCode', { code: code.value }).then((response) => {
          if (response.isPhoneVerified) {
            store.dispatch('profile/profile');
            emit('process');
          }
        }).catch(error => (errorMessages.value = { code: handleError(error) }));
      } else {
        validate();
      }
    };

    const back = () => {
      emit('back');
    };

    return {
      form,
      code,
      onSubmit,
      valid,
      validate,
      countDown,
      countDownTimer,
      resendCode,
      errorMessages,
      back,
      validators: { required },
    };
  },
};
</script>
